<template>
    <el-dialog
            title="查看三维资源"
            :visible.sync="dialogVisible"
            :close-on-click-modal="false"
            :fullscreen="true"
            :close-on-press-escape="false"
            :before-close="handleClose">
        <div class="modelBox">
            <iframe id="mainIframe" ref="mainIframe3" name="mainIframe" class="iframe"
                    :src="iframeSrc" frameborder="0" width="100%" height="100%"/>
<!--            <div class="text_right">-->
<!--                <el-select class="yangshi" size="small" v-model="modelType" @change="changeSelect" placeholder="请选择" style="width: 150px">-->
<!--                    <el-option-->
<!--                        v-for="item in modelTypeOpt"-->
<!--                        :key="item.id"-->
<!--                        :label="item.fieldName"-->
<!--                        :value="item.id">-->
<!--                    </el-option>-->
<!--                </el-select>-->
<!--            </div>-->
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: "seeThreeFile",
    data(){
        return{
            dialogVisible:false,
            iframeSrc: '/static/threeJs/examples/test_obj.html',
            modelType:'',
            modelTypeOpt: [],
            threeDate:{},
            dataa:true,
        }
    },

    methods:{
        init(threeDate){
            this.threeDate = threeDate
            this.dialogVisible = true
            this.$nextTick(()=>{
                this.changeSelect()
            })
        },

        handleClose(){
            this.dialogVisible = false
        },

        changeSelect(id) {
            var that = this
            let iframeData
            if (that.threeDate.stl) {  // stl模型
                this.iframeSrc = '/static/threeJs/examples/basics_stl.html'
                iframeData = {
                    stlUrl: that.threeDate.stl.netUrl,
                }
            } else if (that.threeDate.obj) {  // obj模型
                this.iframeSrc = '/static/threeJs/examples/test_obj.html'
                iframeData = {
                    mtlUrl: that.threeDate.mtl.netUrl,
                    objUrl: that.threeDate.obj.netUrl,
                    imgUrl: that.threeDate.img,
                }
            } else {
                this.$message('缺失文件')
                return;
            }
            console.log(iframeData);
            // 使用ref 获取 dom
            // this.$nextTick(()=>{
            setTimeout(()=>{
                const mapFrame = this.$refs.mainIframe3
                // 因为iframe页面打开就已经加载 获取接口成功后刷新他
                console.log(mapFrame)
                mapFrame.contentWindow.location.reload()
                if (mapFrame.attachEvent) {
                    // 兼容浏览器判断 // iframe的加载完成函数
                    mapFrame.attachEvent('onload', function () {
                        const iframeWin = mapFrame.contentWindow
                        //传递参数
                        iframeWin.postMessage(iframeData, '*')
                    })
                } else {
                    // iframe的加载完成函数
                    mapFrame.onload = function () {
                        const iframeWin = mapFrame.contentWindow
                        console.log(iframeWin);
                        console.log(iframeData);
                        //传递参数
                        iframeWin.postMessage(iframeData, '*')
                    }
                }
                return
            },100)
        },
    }
}
</script>

<style scoped>
.modelBox {
    height: calc(100vh - 164px);
    overflow-y: hidden;
    margin-bottom: 15px;
}

.iframe{
    width: 100%;
}

.yangshi{
    position: absolute;
    bottom: 40px;
    right: 20px;
}
</style>
